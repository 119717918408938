import { FormattedMessage } from 'react-intl'

import { useClub } from 'contexts/club'
import applyMods from '@/shared/utils/applyMods'
import { isUndefined } from '@/shared/utils/lodashFunc'
import { overviewTableStyles } from './styles'
import { eventPageMessages } from 'components/Event/consts'
import { Paragraph2 } from '../Typography'

export interface IOverviewTableProps {
    rows: any[];
    entityKey: string | number;
    className?: string;
    isEditMode: boolean;
    header: {
        [key: string]: {
            acronym: string;
            showable?: boolean;
        };
    };
    entityNameHeaderNode: React.ReactNode;
    moveNumber?: boolean;
    hideHeaderExceptName?: boolean;
    mods?: string;
    children?: React.ReactNode;
    renderHeaderAndRows?: boolean;
    translations?: {[key: string]: string};
    
    getIsExpanded?: (entity) => boolean;
    getAfterRowNode?: (entity) => React.ReactNode;
    getEntityNameNode: (entity, row) => React.ReactNode;
    getFieldKey: (key: string, entityId: number, index: number) => string;
    getEntityNumberNode?: (entity, index: number) => React.ReactNode;
    headerDisplay?: 'name' | 'acronym';
    highlightRow?: (entity) => boolean;
}

export default function OverviewTable(props: IOverviewTableProps): React.ReactElement {
    const { isFlf } = useClub()
    const classes = overviewTableStyles()
    const tableClass = classes.overviewTable
    const tableContainerClass = classes.wrapper
    const tableRelativeContainerClass = classes.root
    const tableEditClass = `${tableClass} ${tableClass}--edit`
    const tableRowClass = classes.overviewTableRow
    const tableRowExpandedClass = `${tableRowClass}--expanded`
    const tableCellClass = classes.overviewTableRowCell
    const tableRugbyCellClass = `${tableCellClass}--extended`
    const { headerDisplay = 'acronym' } = props
    const { highlightRow = () => false } = props

    function renderCell({
        isSticky = false, key = null, children, className = '', isHeader = false, isDarkBackground = false
    }): React.ReactNode {
        return (
            <td key={key}
                className={`${tableCellClass}${isSticky ? ' sticky-col' : ''}${className ? ` ${className}` : ''} ${isHeader? 'header' : 'body'} ${isDarkBackground ? 'bodyDarkBackground' : ''}`}>
                <div>
                    <Paragraph2>
                        {children}
                    </Paragraph2>
                </div>
            </td>
        )
    }

    function renderRow({
        key = null, isExpanded = false, children, className = '',
    }): React.ReactNode {
        return (
            <tr key={key}
                className={`${key} ${tableRowClass}${isExpanded ? ` ${tableRowExpandedClass}` : ''} ${className}`}>
                {children}
            </tr>
        )
    }

    function labelWithHighlight(shouldHighlight, label) {
        if(shouldHighlight) {
            return <b>{label}</b>
        }
        return label
    }

    function renderRows(): React.ReactNode {
        const { rows, entityKey, header, moveNumber, getAfterRowNode, getEntityNameNode, getIsExpanded, getEntityNumberNode } = props

        return (
            <tbody>
                {rows.map((rowValues, index) => {
                    const entity = rowValues[entityKey]
                    const shouldHighlight = highlightRow(entity)
                    const isDarkBackground = index % 2 != 0 && isFlf
                    return (
                        renderRow({
                            isExpanded: getIsExpanded && getIsExpanded(entity), key: index, children: (
                                <>
                                    {renderCell({
                                        key: `row_key_${index}_1`,
                                        isSticky: true,
                                        className: 'positionColumn',
                                        children: labelWithHighlight(shouldHighlight, moveNumber ? `${entity.row}.` : getEntityNumberNode ? getEntityNumberNode(entity, index) : `${index + 1}.`),
                                        isDarkBackground
                                    })}

                                    {renderCell({
                                        key: `row_key_${index}_2`,
                                        isSticky: true,
                                        className: `nameColumn ${isFlf ? 'nameColumnFlf' : ''}`,
                                        children: labelWithHighlight(shouldHighlight, getEntityNameNode(entity, rowValues)),
                                        isDarkBackground
                                    })}

                                    {moveNumber && renderCell({
                                        key: `row_key_${index}_3`,
                                        isSticky: true,
                                        children: labelWithHighlight(shouldHighlight, getEntityNumberNode ? getEntityNumberNode(entity, index) : `${index + 1}.`),
                                        className: tableRugbyCellClass,
                                        isDarkBackground
                                    })}
        
                                    {!isUndefined(header) && Object.keys(header).map((key) => {
                                            const value = header[key]
                                            if (value.showable === false) return null
                                            return renderCell({
                                                key: `${index}-${key}`, children: labelWithHighlight(shouldHighlight, rowValues[key] || 0),
                                                isDarkBackground
                                            })
                                        }
                                    )}

                                    {getAfterRowNode && renderCell({
                                        key: `row_key_${index}_4`,
                                        children: labelWithHighlight(shouldHighlight, getAfterRowNode(entity)),
                                        isDarkBackground
                                    })}
                                </>
                            )
                        })
                    )
                })}
            </tbody>
        )
    }

    function renderHeader(): React.ReactNode {
        const { header, entityNameHeaderNode, moveNumber, hideHeaderExceptName, translations, getAfterRowNode } = props

        return (
            <thead>
                {renderRow({
                    key:'header',
                    className: classes.header,
                    children: (
                        <>
                            {renderCell({
                                key: 'header_1',
                                isSticky: true,
                                isHeader: true,
                                className: 'positionColumn',
                                children: <span style={hideHeaderExceptName ? { opacity: 0 } : {}}>{
                                    // The lineup for rugby is different as we add the position 
                                    // and the number/(jersey number) moves to the right side of the player name
                                    moveNumber
                                        ? <FormattedMessage {...eventPageMessages['gamesheet.player.position']} />
                                        : '#' 
                                }</span>
                            })}

                            {renderCell({
                                key: 'header_2',
                                isHeader: true,
                                isSticky: true, children: entityNameHeaderNode,
                                className: `nameColumn ${isFlf ? 'nameColumnFlf' : ''}`
                            })}

                            {moveNumber && renderCell({
                                key: 'header_3',
                                isHeader: true,
                                isSticky: true, children: <span style={hideHeaderExceptName ? { opacity: 0 } : {}}>#</span>,
                                className: tableRugbyCellClass
                            })}

                            {!isUndefined(header) && Object.keys(header).map((key) => {
                                const value = header[key]
                                if (value.showable === false) return null
                                return renderCell({
                                    isHeader: true,
                                    key, children: (
                                        <span style={hideHeaderExceptName ? { opacity: 0 } : {}}>
                                            {translations ? translations[value[headerDisplay]] : (
                                                <FormattedMessage {...{ id: value[headerDisplay], defaultMessage: '###' }} />
                                            )}
                                        </span>
                                    )
                                })
                            })}

                            {getAfterRowNode && renderCell({ key: 'header_4', children: null })}
                        </>
                    )
                })}
            </thead>
        )
    }

    function renderTable(): React.ReactNode {
        const { className, isEditMode, children, moveNumber } = props
        const extendedClass = 'extended'

        return (
            <table className={`${!isEditMode ? `${tableClass} ` : ''}${isEditMode ? `${tableEditClass} ` : ''}${moveNumber ? `${extendedClass} ` : ''}${className}`}>
                {renderHeader()}

                {renderRows()}

                {children}
            </table>
        )
    }

    const { mods, renderHeaderAndRows } = props

        if (renderHeaderAndRows) {
            return (
                <>
                    {renderHeader()}

                    {renderRows()}
                </>
            )
        }

        return (
            <div className={applyMods(tableRelativeContainerClass, mods)}>
                <div className={tableContainerClass}>
                    <div />

                    {renderTable()}
                </div>
            </div>
        )
}
